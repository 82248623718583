import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/app/AppHomePage.vue";
import AccessEnum from "@/access/accessEnum";
import AppDetailPage from "@/views/app/AppDetailPage.vue";
import AdminUserPage from "@/views/admin/user/AdminUserPage.vue";
import noAuthPage from "@/views/user/NoAuthPage.vue";
import AdminAppPage from "@/views/admin/yida/AdminAppPage.vue";
import AdminScoringResultPage from "@/views/admin/yida/AdminScoringResultPage.vue";
import AdminQuestionPage from "@/views/admin/yida/AdminQuestionPage.vue";
import AdminUserAnswerPage from "@/views/admin/yida/AdminUserAnswerPage.vue";
import addQuestionPage from "@/views/app/add/addQuestionPage.vue";
import addAppPage from "@/views/app/add/addAppPage.vue";
import addScoringResultPage from "@/views/app/add/addScoringResultPage.vue";
import DoAnswerPage from "@/views/app/answer/DoAnswerPage.vue";
import AnswerResult from "@/views/app/answer/AnswerResult.vue";
import MyUserPage from "@/views/user/MyUserPage.vue";
import AppStatisticPage from "@/views/admin/statistic/AppStatisticPage.vue";
import UserLoginPage from "@/views/user/UserLoginPage.vue";
import InterviewQuestionHomePage from "@/views/interview/interviewQuestionBank/InterviewQuestionBankPage.vue";
// @ts-ignore
import AdminInterviewQuestionPage from "@/views/admin/interview/AdminInterviewQuestionPage.vue";
import AdminInterviewQuestionBankPage from "@/views/admin/interview/AdminInterviewQuestionBankPage.vue";
// @ts-ignore
import AdminInterviewQuestionBankQuestionPage from "@/views/admin/interview/AdminInterviewQuestionBankQuestionPage.vue";

import BankDetailPage from "@/views/interview/interviewQuestionBank/BankDetailPage.vue";
import InterviewQuestionDetailPage from "@/views/interview/interviewQuestion/InterviewQuestionDetailPage.vue";

import InterviewQuestionListPage from "@/views/interview/interviewQuestion/InterviewQuestionListPage.vue";
import InterviewQuestionListMyFavourPage from "@/views/interview/interviewQuestion/InterviewQuestionListMyFavourPage.vue";
import PostHomePage from "@/views/post/PostHomePage.vue";
import AppHomePage from "@/views/app/AppHomePage.vue";
import PostDetailPage from "@/views/post/PostDetailPage.vue";
import AdminPostPage from "@/views/admin/post/AdminPostPage.vue";
import PostEditPage from "@/views/post/PostEditPage.vue";
import AdminBugPage from "@/views/admin/bug/AdminBugPage.vue";
import AdminCommentPage from "@/views/admin/post/AdminCommentPage.vue";
import AIChat from "@/views/chat/AIChat.vue";
import ChatMessagePage from "@/views/chat/ChatMessagePage.vue";

export const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "首页",
    meta: {
      showInMenu: true,
    },
    children: [
      {
        path: "/",
        name: "首页",
        component: PostHomePage,
      },
      {
        path: "/post/detail/:id",
        name: "帖子详细",
        component: PostDetailPage,
        props: true,
      },
      {
        path: "/post/edit/:id",
        name: "帖子编辑",
        component: PostEditPage,
        props: true,
      },
    ],
  },
  {
    path: "/interview",
    name: "面试题",
    meta: {
      showInMenu: true,
    },
    children: [
      {
        path: "/interview",
        name: "面试题",
        component: InterviewQuestionListPage,
      },
      {
        path: "/bank/detail/:id",
        name: "面试题库详细",
        component: BankDetailPage,
        props: true,
      },
      {
        path: "/interviewQuestion/detail/:id",
        name: "面试题详细",
        component: InterviewQuestionDetailPage,
        props: true,
      },
      {
        path: "/interviewQuestion/detail/:bankId/:id",
        name: "面试题详细（题库内）",
        component: InterviewQuestionDetailPage,
        props: true,
      },
    ],
  },
  {
    path: "/interviewBank",
    name: "面试题库",
    component: InterviewQuestionHomePage,
    meta: {
      showInMenu: true,
    },
  },
  {
    path: "/app",
    name: "问答",
    meta: {
      showInMenu: true,
    },
    children: [
      {
        path: "/app",
        name: "问答",
        component: AppHomePage,
      },
      {
        path: "/app/detail/:id",
        name: "应用详情",
        component: AppDetailPage,
        props: true,
      },
      {
        path: "/add/app",
        name: "创建应用",
        component: addAppPage,
      },
      {
        path: "/add/app/:appId",
        name: "修改应用",
        component: addAppPage,
        props: true,
      },
      {
        path: "/add/question/:appId",
        name: "创建题目",
        component: addQuestionPage,
        props: true,
      },
      {
        path: "/add/scoringResult/:appId",
        name: "创建评分",
        component: addScoringResultPage,
        props: true,
      },
      {
        path: "/answer/do/:appId",
        name: "答题",
        component: DoAnswerPage,
        props: true,
        meta: {
          access: AccessEnum.USER,
        },
      },
      {
        path: "/answer/result/:id",
        name: "答题结果页面",
        component: AnswerResult,
        props: true,
        meta: {
          access: AccessEnum.USER,
        },
      },
    ],
  },

  {
    path: "/user/login",
    name: "用户",
    props: true,
    children: [
      {
        path: "/user/login",
        name: "用户",
        component: UserLoginPage,
        props: true,
      },
      {
        path: "/my/user",
        name: "我的信息",
        component: MyUserPage,
        props: true,
        meta: {
          access: AccessEnum.USER,
        },
      },
      {
        path: "/my/favour/interviewQuestion",
        name: "我收藏的面试题",
        component: InterviewQuestionListMyFavourPage,
        props: true,
        meta: {
          access: AccessEnum.USER,
        },
      },
    ],
  },
  // {
  //   path: "/chat/message",
  //   name: "聊天",
  //   props: true,
  //   meta: {
  //     showInMenu: true,
  //   },
  //   children: [
  //     {
  //       path: "/chat/ai",
  //       name: "AI聊天",
  //       component: AIChat,
  //       props: true,
  //     },
  //     {
  //       path: "/chat/message",
  //       name: "消息",
  //       component: ChatMessagePage,
  //       props: true,
  //     },
  //   ],
  // },

  {
    path: "/admin/user",
    name: "管理",
    meta: {
      showInMenu: true,
      access: AccessEnum.ADMIN,
    },
    children: [
      {
        path: "/admin/user",
        name: "管理",
        component: AdminUserPage,
      },
      {
        path: "/admin/user",
        name: "用户管理",
        component: AdminUserPage,
        meta: {
          access: AccessEnum.ADMIN,
        },
      },
      {
        path: "/admin/app",
        name: "应用管理",
        component: AdminAppPage,
        meta: {
          access: AccessEnum.ADMIN,
        },
      },
      {
        path: "/admin/question",
        name: "问题管理",
        component: AdminQuestionPage,
        meta: {
          access: AccessEnum.ADMIN,
        },
      },
      {
        path: "/admin/scoringResult",
        name: "评分结果管理",
        component: AdminScoringResultPage,
        meta: {
          access: AccessEnum.ADMIN,
        },
      },
      {
        path: "/admin/userAnswer",
        name: "用户答案管理",
        component: AdminUserAnswerPage,
        meta: {
          access: AccessEnum.ADMIN,
        },
      },
      {
        path: "/admin/appStatistic",
        name: "应用统计",
        component: AppStatisticPage,
        meta: {
          access: AccessEnum.ADMIN,
        },
      },
      {
        path: "/admin/interview",
        name: "题目管理",
        component: AdminInterviewQuestionPage,
        meta: {
          access: AccessEnum.ADMIN,
        },
      },
      {
        path: "/admin/interviewQuestionBank",
        name: "题库管理",
        component: AdminInterviewQuestionBankPage,
        meta: {
          access: AccessEnum.ADMIN,
        },
      },
      {
        path: "/admin/interviewQuestionBankQuestion",
        name: "题库关联表",
        component: AdminInterviewQuestionBankQuestionPage,
        meta: {
          access: AccessEnum.ADMIN,
        },
      },
      {
        path: "/admin/post",
        name: "帖子管理",
        component: AdminPostPage,
        meta: {
          access: AccessEnum.ADMIN,
        },
      },
      {
        path: "/admin/comment",
        name: "评论管理",
        component: AdminCommentPage,
        meta: {
          access: AccessEnum.ADMIN,
        },
      },
      {
        path: "/admin/bug",
        name: "BUG管理",
        component: AdminBugPage,
        meta: {
          access: AccessEnum.ADMIN,
        },
      },
    ],
  },

  {
    path: "/noAuth",
    name: "无权限访问",
    component: noAuthPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
