<template>
  <div class="userLogin">
    <div id="userPhoneLoginPage" v-if="index == 0">
      <a-typography-title :heading="5" style="margin-bottom: 20px">
        手机号登录
      </a-typography-title>
      <a-form
        :model="phoneForm"
        label-align="left"
        auto-label-width
        @submit="phoneLogin"
      >
        <a-form-item field="userPhone">
          <a-input
            v-model="phoneForm.userPhone"
            placeholder="请输入手机号..."
            class="input"
          >
            <template #suffix>
              <icon-phone />
            </template>
          </a-input>
        </a-form-item>
        <a-form-item field="phoneCode">
          <a-input
            v-model="phoneForm.phoneCode"
            placeholder="请输入手机验证码..."
            class="input"
          >
            <template #suffix>
              <span id="getCodeButton" @click="getPhoneCode()">
                获取验证码
              </span>
            </template>
          </a-input>
        </a-form-item>
        <a-form-item field="code">
          <a-space>
            <a-input
              v-model="imgCode"
              placeholder="请输入验证码"
              class="input"
            />
            <img style="width: 80px; height: 35px" :src="codeMap.image" />
            <a-button id="getCodeButton" @click="getCode()" class="input">
              刷新
            </a-button>
          </a-space>
        </a-form-item>
        <a-button
          type="primary"
          html-type="submit"
          class="input"
          style="width: 120px; margin: 0 auto 20px"
          >登录 / 注册
        </a-button>
      </a-form>
      <a-link @click="changePage(1)"> 账户密码登录</a-link>
    </div>
    <div id="userLoginPage" v-else-if="index == 1">
      <a-typography-title :heading="5" style="margin-bottom: 20px">
        用户登录
      </a-typography-title>
      <a-form :model="form" label-align="left" auto-label-width @submit="login">
        <a-form-item field="userAccount" label="账号">
          <a-input
            v-model="form.userAccount"
            placeholder="请输入账号..."
            class="input"
          />
        </a-form-item>
        <a-form-item
          field="userPassword"
          tooltip="密码不小于8位数"
          label="密码"
        >
          <a-input-password
            v-model="form.userPassword"
            placeholder="请输入密码..."
            :defaultVisibility="true"
            class="input"
          />
        </a-form-item>
        <a-button
          type="primary"
          html-type="submit"
          style="width: 100px; margin: 0 auto 10px"
          class="input"
          >登录
        </a-button>
      </a-form>

      <a-link @click="changePage(0)"> 手机号登录</a-link>
      <a-link @click="changePage(2)" style="margin-left: 220px">
        去注册>
      </a-link>
    </div>
    <div id="userRegisterPage" v-else-if="index == 2">
      <a-typography-title :heading="5" style="margin-bottom: 20px">
        用户注册
      </a-typography-title>
      <a-form
        :model="registerForm"
        label-align="left"
        auto-label-width
        @submit="register"
      >
        <a-form-item field="userName" label="名称">
          <a-input
            v-model="registerForm.userName"
            placeholder="请输入名称..."
            class="input"
          />
        </a-form-item>
        <a-form-item field="userAccount" label="账号">
          <a-input
            v-model="registerForm.userAccount"
            placeholder="请输入账号..."
            class="input"
          />
        </a-form-item>
        <a-form-item
          field="userPassword"
          tooltip="密码不小于8位数"
          label="密码"
        >
          <a-input-password
            v-model="registerForm.userPassword"
            placeholder="请输入密码..."
            :defaultVisibility="true"
            class="input"
          />
        </a-form-item>
        <a-form-item
          field="checkPassword"
          tooltip="密码不小于8位数"
          label="确认"
        >
          <a-input-password
            v-model="registerForm.checkPassword"
            placeholder="请再输入密码..."
            :defaultVisibility="true"
            class="input"
          />
        </a-form-item>
        <a-button
          type="primary"
          html-type="submit"
          style="width: 100px; margin: 0 auto 10px"
          class="input"
          >注册
        </a-button>
      </a-form>
      <a-link @click="changePage(0)"> 手机号登录</a-link>
      <a-link @click="changePage(2)" style="margin-left: 220px">
        去登录>
      </a-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref } from "vue";
import API from "@/api";
import {
  userLoginUsingPost,
  userRegisterUsingPost,
} from "@/api/userController";
import { useLoginUserStore } from "@/store/userStore";
import message from "@arco-design/web-vue/es/message";
import { useRouter } from "vue-router";
import {
  codeUsingGet,
  getCodeUsingGet,
  phoneLoginUsingPost,
} from "@/request/api/userController";

const router = useRouter();
const userStore = useLoginUserStore();

/**
 * 手机号登录
 */
const phoneForm = reactive({
  userPhone: "",
  phoneCode: "",
} as API.UserPhoneRequest);

/**
 * 手机号登录登录
 */
const phoneLogin = async () => {
  const response = await phoneLoginUsingPost(phoneForm);
  if (response.data.code === 0) {
    await userStore.fetchLoginUser();
    message.success("登录成功");
    await router.push({
      path: "/",
      replace: true,
    });
  } else {
    message.error(response.data.message as string);
  }
};
/**
 * 获取手机验证码
 */
const loading = ref(false);
const getPhoneCode = async () => {
  if (phoneForm.userPhone === "" || loading.value == true) {
    return;
  }
  if (imgCode.value == "") {
    message.error("请输入图形验证码");
    return;
  }
  const phone = ref<API.codeUsingGETParams>({
    userPhone: phoneForm.userPhone as string,
    imgCodeKey: codeMap.value.key,
    imgCode: imgCode.value,
  });
  const response = await codeUsingGet(phone.value);
  if (response.data.code === 0) {
    message.success("验证码已发送");
    loading.value = true;
    // 设置倒计时
    countdown(60);
  } else {
    message.error(response.data.message as string);
  }
};
/**
 * 图形验证码
 */
const imgCode = ref("");
const codeMap = ref<any>({
  key: "",
  image: "",
});
const getCode = async () => {
  const res = await getCodeUsingGet({
    key: codeMap.value.key,
  });
  if (res.data.code === 0) {
    codeMap.value = res.data.data;
  } else {
    message.error(res.data.message as string);
  }
};

/**
 * 倒计时
 * @param t
 */
function countdown(t: number): void {
  loading.value = true;
  const timer = t > 0 ? t : 0;
  const button = document.getElementById("getCodeButton") as any;
  button.innerText = `${timer}秒后重新获取`;
  if (timer > 0) {
    setTimeout(() => countdown(timer - 1), 1000);
  } else {
    button.innerText = `获取验证码`;
    loading.value = false;
    return;
  }
}

/**
 * 用户密码登录
 */
const form = reactive({
  userAccount: "",
  userPassword: "",
} as API.UserLoginRequest);

/**
 * 用户登录
 */
const login = async () => {
  const response = await userLoginUsingPost(form);
  if (response.data.code === 0) {
    await userStore.fetchLoginUser();
    message.success("登录成功");
    await router.push({
      path: "/",
      replace: true,
    });
  } else {
    message.error(response.data.message as string);
  }
};
/**
 * 用户注册
 */
const registerForm = reactive({
  userName: "",
  userAccount: "",
  userPassword: "",
  checkPassword: "",
} as API.UserRegisterRequest);
/**
 * 用户注册
 */
const register = async () => {
  const response = await userRegisterUsingPost(registerForm);
  if (response.data.code === 0) {
    message.success("注册成功");
    changePage(1);
  } else {
    message.error(("注册失败，" + response.data.message) as string);
  }
};
/**
 * 监听 searchParams 变量，改变时触发数据的重新加载
 */
onMounted(() => {
  getCode();
});
/**
 * 切换页面
 */
const index = ref<number>(0);
const changePage = (i: number) => {
  index.value = i;
};
</script>

<style scoped>
.userLogin {
  padding: 10px 40px 20px 30px;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.329);
  border-radius: 20px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.13);
  backdrop-filter: blur(10px);
}

.input {
  border-radius: 10px;
}
</style>
